import Carousel from "react-multi-carousel"
import {Container, Col, Row} from "react-bootstrap";
import TestCarousel from '../components/TestCarousel';
import React, {useState} from 'react';




function Articles() {
    return (
        <>
    <div 
    className="archive-section">
         <div className="archive-section-title-container">
            <h2 className="archive-section-title-container-title"><u>Articles</u></h2>
            {/* <p className="-archive-section-title-container-subtext">some things.</p> */}
          </div>

          <div className="archive-list-container">

            <ul>
                <li>
                    <a href="https://www.ycombinator.com/launches/IAP-inkeep-conversational-search-for-your-developer-product">Inkeep - Conversational search for your developer product </a>
                </li>
                <li>
                    <a href="https://www.nobelprize.org/prizes/medicine/2017/press-release/">2017 Nobel Prize in Physiology or Medicine</a>
                </li>
                {/* <li>
                    <a href="https://www.oc.edu/news/alumni-having-a-ball-with-star-wars-work">2017 Nobel Prize in Physiology or Medicine</a>
                </li> */}
                
                
               
            </ul>
          </div>
    </div>
        </>
    )
}

export default Articles;


