import Carousel from "react-multi-carousel"
import {Container, Col, Row} from "react-bootstrap";
import TestCarousel from '../components/TestCarousel';
import React, {useState} from 'react';




function Other() {
    return (
        <>
    <div 
    className="archive-section">
         <div className="archive-section-title-container">
            <h2 className="archive-section-title-container-title"><u>Other</u></h2>
            {/* <p className="-archive-section-title-container-subtext">some things.</p> */}
          </div>
          <div className="archive-list-container">
            <ul>
                
                <li>
                    <a href="https://www.youtube.com/watch?v=N-L-WZrnpBA" target="_blank" rel="noopener noreferrer">
                        SpaceX Starship Launch 4: Everything That Happened in 12 Minutes</a>
                </li>
                <li>
                    <a href="https://www.youtube.com/watch?v=YNY4UFaHbP4" target="_blank" rel="noopener noreferrer">
                        Just learn how to get stuff done</a>
                </li>

               
                
            </ul>
          </div>
          <div className="archive-list-container">
            <ul>
                <li>
                    <a href="https://www.wnycstudios.org/podcasts/otm/segments/rip-vile-rat" target="_blank" rel="noopener noreferrer">
                        RIP Vile Rat</a>
                </li>
                <li>
                    <a href="https://www.youtube.com/watch?v=DVtNt-6OTM8" target="_blank" rel="noopener noreferrer">
                        Pavane for a Dead Princess</a>
                </li>
                <li>
                    <a href="https://www.youtube.com/watch?v=_5Tie0SY1P8" target="_blank" rel="noopener noreferrer">
                        Oblivion</a>
                </li>
            </ul>
          </div>
          {/* <div className="archive-list-container">
            <ul>
                <li>
                    <a href="https://en.wikipedia.org/wiki/Three-body_problem" target="_blank" rel="noopener noreferrer">
                        Three-body problem</a>
                </li>
                <li>
                    <a href="https://en.wikipedia.org/wiki/Maxwell%27s_demon" target="_blank" rel="noopener noreferrer">
                        Maxwell's daemon</a>
                </li>
                <li>
                    <a href="https://en.wikipedia.org/wiki/Dedekind_cut" target="_blank" rel="noopener noreferrer">
                        Dedekind Cut</a>
                </li> 
            </ul>
          </div> */}
    </div>
        </>
    )
}

export default Other;


