// import Poetry from './Poetry'
import TestCarousel from './TestCarousel';
import Things from '../archive/Things'
import Movies from '../archive/Movies'
import Other from '../archive/Other'
import Articles from '../archive/Articles'
import Quotes from '../archive/Quotes'
import Books from '../archive/Books'
import Videos from '../archive/Videos'
import Poetry from "../archive/Poetry"
import Music from "../archive/Music"
import ArchiveFooter from "../archive/ArchiveFooter"
import Footer from "../archive/Footer"
import BlogList from "../blogs/BlogList"




export default function Archive() {

  
    return (
        <>
        <div className="archive-giant-container">
        <h1>Archive</h1>
        <Poetry/>
        <Articles/>
        <Movies/>
        <Other/>
        <Quotes/>
        <Things/>
        <Books/>
        <Music/>
        <Footer/>
       
        </div>
        </>
    )
}


