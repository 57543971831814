import { BrowserRouter, Route, Routes, Outlet, Link } from 'react-router-dom';
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import React, { useRef } from "react";

// bloglist + footer
import BlogList from "./BlogList"
import Footer from "../archive/Footer"

// stuff i use

// dash &#x23E4;






import blog_image9 from "../assets/pensacola.jpg";

export default function Formal() {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);
  const section8Ref = useRef(null);

  // Function to scroll to a specific section
  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <div className="blog-page">
        <BlogList />

        <div className="upvote-and-title">
          {/* <ItemTest itemId={3}/> */}
          <h2>Hallucinations</h2>
          <date><i>- x minute read</i></date>
        </div>

        <div className="blog-page-quote">
          <p>"being deeply in love with life and existence and interactions with human beings&#x23E4; that's the main fear." - duke winston</p>
        </div>



        <div className="blog-page-my-words">
          <h4>Table of Contents:</h4>
          <ul>
            <li><span>1. <u onClick={() => scrollToSection(section1Ref)}> Correct-By-Construction</u></span></li>
            <li><span>2. <u onClick={() => scrollToSection(section2Ref)}> Automata, Computation, Formal Languages</u></span></li>
            <li><span>3. <u onClick={() => scrollToSection(section3Ref)}> Temporal Logic</u></span></li>
            <li><span>4. <u onClick={() => scrollToSection(section4Ref)}> Frameworks, Algorithms, Planning</u></span></li>
            <li><span>5. <u onClick={() => scrollToSection(section5Ref)}> Markov Decision Processes</u></span></li>
            <li><span>6. <u onClick={() => scrollToSection(section6Ref)}> Belief-based Planning</u></span></li>
            <li><span>7. <u onClick={() => scrollToSection(section7Ref)}> Reactive Synthesis: Turn-based, Zero-sum games on graphs*</u></span></li>
            <li><span>8. <u onClick={() => scrollToSection(section8Ref)}> Other</u></span></li>
          </ul>
          <ul>
            <li><i>examples *</i></li>

          </ul>
        </div>


        <div ref={section1Ref} id="section1" className="blog-page-my-words">
          <h4>Correct-By-Construction</h4>
          <p>I know little about this topic.</p>

        </div>


        <div ref={section2Ref} id="section2" className="blog-page-my-words">
          <h4>Automata, Computation, Formal Languages</h4>
          <p>
          </p>
        </div>


        <div ref={section3Ref} id="section3" className="blog-page-my-words">
          <h2>Temporal Logic</h2>
          <p></p>
        </div>




        <div ref={section4Ref} id="section4" className="blog-page-my-words">
          <h2>Frameworks, Algorithms, Planning</h2>
          <p></p>
        </div>














        <div className="blog-page-the-end">
          <p><i>C'est Tout</i></p>
        </div>
        <Footer />
      </div>
    </>
  )
}