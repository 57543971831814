
import React, {useState} from "react";
import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style

import Footer from "../archive/ArchiveFooter"
import BlogList from "./BlogList"
import "./Reads.css"

import SunAlsoRises from "../assets/Books/TheSunAlsoRises.jpg";



// dropdown menu
import DropdownNotes from "./DropdownNotes";

// PopUpTemplate
import PopUpModal from "../components/PopUpModal"





export default function Reads() {

    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal)
        
    };

    if(modal) {
        document.body.classList.add('active-model')
    }else{
        document.body.classList.remove('active-modal')
        
    };
    
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal2 = () => {
      setModalOpen(!modalOpen);
    };

    


    
    
    
    


    return (
        <>
        <div className="blog-page">
            <BlogList/>

            <div className="blog-page-title">
                    <h3>Reads</h3>
                </div>
            <div className="blog-page-my-words">
                <h4>current:</h4>
 
                <p>
                    <ul>
                    {/* <li onClick = {toggleModal} className ="btn-modal"><u>The Very Hungry Caterpillar</u></li> */}
                    <li>Skin in the Game (Taleb 2017)</li>
                    <li>The Hard Thing About Hard Things (Horowitz 2014)</li>
                    <li>Thinking, Fast and Slow (Kahneman 2011)</li>
                    <li onClick={toggleModal} className="btn-modal"><u>The Sun Also Rises (Hemingway 1926)</u></li>
                        <li>Working (Terkel 1974)</li>
                        <li>invisible cities (calvino 1972)</li>
                        <li>Originals (Grant 2016) </li>
                        <li>Think Again (Grant 2021)</li>
                    </ul>
                </p>
            </div>

            <div className ="blog-page-my-words">
                <h4>other:</h4>
                <p>
                    <ul>
                        <li><a href="https://mitadmissions.org/blogs/entry/dream-machine/" target="_blank">dream machine</a></li>
                        <li><a href="https://medium.com/things-ive-written/the-elephants-182870501589" target="_blank">The Elephants</a></li>
                        <li><a href ="https://en.wikipedia.org/wiki/Punxsutawney_Phil" target="_blank"> Punxsutawney Phil</a></li>
                    </ul>
                </p>
            </div>

        
             <div className="blog-page-the-end">
            <p><i>C'est Tout</i></p>
            </div>
                </div>
                <>
      {/* <button onClick={toggleModal} className="btn-modal">
        Open
      </button> */}



{/* my modal */}
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <h2>The Sun Also Rises</h2>
            <br></br>
            <div className ="movie-title-container">

<div className = "left-side">

    
    <a target="_blank" rel="noopener noreferrer">
    <img src={SunAlsoRises}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>  
</div>
<div className= "right-side">
    <p className=""><b>            <p><b>Ernest Hemingway (1926)</b></p>
</b></p>
    <p className="right-side-movie-stars">&#x2606;&#x2606;&#x2606;&#x2606;&#x2606;</p>
    <div className="right-side-movie-notes">
    <p><i>"I am always in love."</i></p>
    </div>
</div>
</div>
        <div className="center-border"></div>
        {/* 
        <div className="bottom-side-movie-comments">
        <p>
        The quintessential novel of the Lost Generation, The Sun Also Rises (Fiesta) is one of Ernest Hemingway's masterpieces and a classic example of
                his spare but powerful writing style. A poignant look at the disillusionment and angst of the post-World War I generation, the novel introduces
                 two of Hemingway's most unforgettable characters: Jake Barnes and Lady Brett Ashley.
        </p>
        </div>
         */}
        <DropdownNotes buttonContent={<p className="movie-folder">notes</p>}>
                <div className="bottom-side-movie-comments">
                    <p>testing something new...</p>
               {/* <p>

                The story follows the flamboyant Brett and the hapless Jake
                  as they journey from the wild nightlife of 1920s Paris to the brutal bullfighting rings of Spain with a motley group of expatriates. It is an age 
                  of moral bankruptcy, spiritual dissolution, unrealized love, and vanishing illusions. First published in 1926, The Sun Also Rises helped to establish 
                  Hemingway as one of the greatest writers of the twentieth century.
               </p> */}
                    </div> 
                </DropdownNotes>
         
            {/* <button className="close-modal" onClick={toggleModal}>
           
            </button> */}
          </div>
        </div>
      )}




      {/* my modal */}
 


  
        
        </>
     
        <Footer/>
        </>
        
    )
}