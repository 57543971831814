import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import {useState, useEffect} from 'react';
import daynight from "../assets/day-and-night.png";
// import "react-toggle/style.css" // for ES6 modules

export default function Header () {
    
    const [mode, setMode] = useState('light'); // State variable to track mode

  // Function to toggle mode
  const toggleMode = () => {
    setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
  };

  // Apply theme based on mode
  useEffect(() => {
    if (mode === 'dark') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [mode]);
    
    

    return(
        <nav 
        className = "nav-bar-container"
        >
            {/* <div className="logo">
            <p>Logo</p>
            </div> */}
            <div 
            // className='nav-bar'
            >
            <ul className='nav-bar'>
                {/* <a href="#">hi</a> */}

                <Link className="pages" to="/">01  :  Home</Link>
                <p className="pages-line">|</p>
                <Link className="pages" to="/Archive">02  :  Archive</Link>
                <p className="pages-line">|</p>
                <Link className="pages" to="/Blog">03  :  Blog</Link>
                <button onClick={toggleMode}><span></span></button>

            </ul>
            </div>
        </nav>
    )
}