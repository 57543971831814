import Footer from "./Footer"
import React from "react";
import LEDButton from "./LEDButton.js";
export default function Home() {
    return (
        <>
        <div className="home-n-blog-page">
        <h1>Andy Tran</h1>
        <div className="blog-page-my-words">
            <ul>
                <li>I am a human.</li>
                <li>I grew up with 8 brothers and 6 sisters in Pensacola.</li>                        
                
                    </ul>
        {/* <p>I am a human.</p>
        <p>I am the 14th in the line of 15.</p> */}
        {/* <h4>Testing Something New:</h4>
        <form action="http://192.168.1.100/toggle" method="get">
        <button className="poetry-button">
            Click Me!
        </button>
        </form> */}
        {/* <LEDButton/> */}
      


        </div>
        <Footer/>
        </div>
        </>
    )
}
