import Carousel from "react-multi-carousel"
import {Container, Col, Row} from "react-bootstrap";
import TestCarousel from '../components/TestCarousel';
import React, {useState} from 'react';

import Dropdown from "../blogs//Dropdown";





function Music() {
    return (
        <>
    <div 
    className="archive-section">
         <div className="archive-section-title-container">
            <h2 className="archive-section-title-container-title"><u>Music</u></h2>
            {/* <p className="archive-section-title-container-subtext">sounds.</p> */}
          </div>


          {/* <Dropdown buttonContent={<p className="movie-folder">watchlist</p>}>
                <div className="movie-tabs">
                    <h4>what's next</h4>
                </div>
            <div className="blog-page-my-words">
            <iframe src="https://open.spotify.com/embed/track/3ISKxnCGKc5B9zr9CTUB3v?utm_source=generator&theme=0" width="70%" height="130" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            
            
            </div>
               
                </Dropdown> */}
          <div className="archive-list-container">

        
            <ul>
                <li>
                    <a href="https://open.spotify.com/user/tranandy012?si=2431e5658bb74e4d">Spotify</a>
                </li>
                <li>
                    <a href="https://www.youtube.com/watch?v=UVCV9EgX4NA" target="_blank" rel="noopener noreferrer">Clairo & Rostam 'Bags'</a>
                </li>
                <li>
                    <a href="https://www.youtube.com/watch?v=Xd1wEy1j9No" target="_blank" rel="noopener noreferrer">The Making of J.Cole's "No Role Modelz"</a>
                </li>
                <li>
                    <a href="https://www.youtube.com/watch?v=DNZHwZlI9W0" target="_blank" rel="noopener noreferrer">[JYPn] Finesse Cover</a>
                </li>
                <li>
                    <a href=" https://www.youtube.com/watch?v=Pg7nBHXQCCA" target="_blank" rel="noopener noreferrer">Red Velvet 레드벨벳 - Psycho @ReVe Festival FINALE</a>
                </li>
                
            </ul>
          </div>
    </div>
        </>
    )
}

export default Music;


