import Carousel from "react-multi-carousel"
import {Container, Col, Row} from "react-bootstrap";
import TestCarousel from '../components/TestCarousel';
import React, {useState} from 'react';




function Books() {
    return (
        <>
    <div 
    className="archive-section">
         <div className="archive-section-title-container">
            <h2 className="archive-section-title-container-title"><u>Books</u></h2>
            <p className="archive-section-title-container-subtext"></p>
          </div>
          <div className="archive-list-container">
            <ul>
               <li>The Holy Bible</li>
               <li>Crime and Punishment (Dostoevsky 1866)</li>
               <li>Myth of Sisyphus (Camus 1942)</li>
               {/* <li>Composing a life (Bateson 1989)</li> */}
               <li>Countdown to Oblivion (Butcher 2022)</li>
               <li>Working (Terkel 1974)</li>
            </ul>
          </div>
    </div>
        </>
    )
}

export default Books;


