import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import blog_image1 from "../assets/IMG_6658.jpg";
import blog_image2 from "../assets/cafe.jpg";
import blog_image3 from "../assets/apap.jpg";
import blog_image4 from "../assets/oceans.jpg";
import blog_image5 from "../assets/steak.jpg";
import blog_image6 from "../assets/hay.jpg";
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"
import BlogCarousel3 from "../components/BlogCarousel3.js"

// upvote button
import ItemTest from "../components/Item.js"

// footer
import Footer from "../archive/Footer"

import BlogList from "./BlogList"
// dash &#x23E4;




import blog_image9 from "../assets/pensacola.jpg";

export default function Twenties() {
    return (
        <>
        <div className="blog-page">
           <BlogList/>

           <div className="upvote-and-title">
           {/* <ItemTest itemId={1}/> */}
           <h2>The Roaring '20s</h2>
           <date><i>- 4 minute read</i></date>
           </div>
                {/* <div className="blog-page-title">
                    <h3>The Roaring '20s</h3>
                </div>
                <div className="blog-page-date">
                    <p>08 Oct 2023<i> - 4 minute read</i></p>
                </div> */}
                <div className="blog-page-quote">
                    <p><i>Destiny, she is kind to you.</i></p> 
                    <p><i>Tomorrow is Wednesday. The water comes.</i></p> 
                    <p><i>At noon, the townspeople gather</i></p> 
                    <p><i>For a mysterious ritual.</i></p>
                </div>
                <div className="blog-page-quote">
                    <p><i>You mean like with real people and everything?</i></p>
                    <p><i>Where?</i></p>
                </div>
                <div className="blog-page-quote">
                    <p><i>A days journey. Follow your shadow.</i></p>
                </div> 
                <div className="blog-page-my-words">
                    <p><i>I turned 20 a few weeks ago. Oddly and primely, the 19th year was particularly energizing, which is the topic of this list&#x23E4; <b>energy</b>.
                        I find it to be an underrated topic that most students and humans who "work" spend too much time trying to understand and too little time effectively figuring out.
                    </i></p>
                    <p><i>
                    An unfortunate truth in life is that you can spend years lingering on the wrong questions.
                         I'm a bit unsure on posting this as I believe I do not currently possess the merit 
                        to deliver advice of this nature. However, if I had to package a set of words to give to different versions of my younger self&#x23E4; this is probably it.</i></p>
               
                    <p><i>
                        Energy is E for everyone. Look for it everywhere. Find it in everything you do.
                        </i></p>
                </div>
                
                <div className="blog-page-my-words">
                    <p>1. be nice.</p>
                    <p>2. your friends & family should rarely be outside the top 3 things on your priority list.</p>
                    <p>3. Spend time with people that you like. Don't spend time with people you don't like.</p>
                    <p>4. Many people want to do "a lot." The world will be on your back. Good luck :) </p>
                    <p>5. Lose track of time more often. Occasionally stay up until the sunrise talking with someone or thinking about something. It might ruin your day, but it might make your year.</p> 
                    <p>6. Life is composed of what you do every day&#x23E4; What you eat, think, feel, truly make up who you are. Students study. Lovers love. The healthy are healthy. 
                        If you want to "be something," you actually have to do it a lot more than you currently think you should. Add another zero. Growth is a compound function.
                        Locally, always overshoot. You're just going to have to trust it will work out.
                    </p>
                    <p>7. Growing up, there is going to be a lot of things out of your control and a lot of stuff that drains your energy. Be an optimist. 
                        You will get through it. Find ways to minimize crap. Focus on the long-term version of "you." 
                        Annoying stuff usually tries to be the main character, but try to let it become a sidenote, or even better&#x23E4; 
                        something that wasn't even on your mind.</p>
                    <p>8. High concentrations of heat and pressure create diamonds in the rough. Try to become a unicorn. Settle for being a diamond.</p>
                    <p>9. Often ask yourself this question: "who has changed my life?" and write down what they said.</p>
                    <p>10. Have meaningful relationships. Making a new one each year is an n^n value function. Find your people.</p>
                    <p>11. Write a lot of stuff down. Make lots of lists. Constantly think about the best thing to do today, week, and year. Heuristic approaches often yield surprising biproducts. Greedy algorithms are pretty effective in this way.</p>
                    <p>12. Drink your coffee slowly, but be skeptical of people who are telling you not to chug, then proceed to chug.</p>
                    <p>13. Even if you think you are better at it than most people, quickly realize that school is more boring than you think. Education is moreso a political problem. 
                        Try not to overestimate the power of classes, but definitely accurately glorify the power of learning.
                        Be playful with this. If you're ambitious, find ways to transform boring activites into meaningful opportunities. It helps curate original minds. Let the system work for you.
                    </p>

                    <p>14. Work & Study: Maximize your function of cognitive output. Eating, sleeping, going on walks are underrated inputs to this function. Minimize the crap that bogs you down.</p>
                    <p>15. Listen to a lot of music.</p>
                    <p>16. Talk to yourself more.</p>
                    <p>17. Figure out a lot in the highs. Quickly find ways to kick out of the lows.</p>
                    <p>18. You don't have to have a reputation of being super organized, but definitely do not have a reputation of being disorganized.</p>
                    <p>19. Don't trust most people. Definitely trust people who are nice to you. Negative nancies usually have a calcified way of "being." They will hurt you in the long run.</p>
                    <p>20. Try not to hang around people who constantly describes others as "alright" or "just competent." Getting stuff done is very important, but
                        constantly bringing energy to the table is magic. Be skeptical of the strength of their network. Definitely hang around people who always have something nice to say about someone else. Optimists are reviving.
                        It takes a dreamer to see light in the darkness.
                    </p>
                    <p>21. It is very difficult to know what you want to do or how to figure out what you are good at. Your gut is wrong most of the time, but it is very right when it's right, and that is worth mountains. Try a lot of things.</p>
                    <p>22. Have relentless energy. Constantly Learn. Find your positive feedback loop. Competition is for losers and the competition find you annoying. Friends find you organic and unstoppable.</p>
                    <p>23. You can study books, but you <i><b>read</b></i> things that are interesting. Read a lot.</p>
                    <p>24. "Just learn how to get stuff done." Be a James Bond.</p>
                    <p>25. "They are revving their engines for you." You are morally obligated to rev back :').</p>
                    <p>Sincerely, The Roaring '20s.</p>
                </div>
                <div className="blog-page-the-end">
                    <p><i>C'est Tout</i></p>
                </div>

                
            <div>

            </div>
            <Footer/>
        </div>
        </>
    )
}